import React, { createContext, useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import awsconfig from "./aws-exports";
import { createGuest } from "./helpers/guestyApiCalls";
import { navigate } from "gatsby";

awsconfig.oauth.redirectSignIn = `${process.env.GATSBY_PUBLIC_GOOGLE_AUTH_REDIRECT}/account/`;
awsconfig.oauth.redirectSignOut = `${process.env.GATSBY_PUBLIC_GOOGLE_AUTH_REDIRECT}/login/`;
Amplify.configure({ ...awsconfig, ssr: true });

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [needsLoginChecked, setNeedsLoginChecked] = useState(true);
  const [user, setUser] = useState();
  const [inSignUpGuestyId, setInSignUpGuestyId] = useState(null);

  useEffect(() => {
    const checkLogin = async () => {
      currentUser();
    };

    if (needsLoginChecked) {
      checkLogin();
    }
  }, [needsLoginChecked, Auth]);

  useEffect(() => {
    const setup = async () => {
      const setupCurrentUser = await currentUser();
    };

    setup();
  }, []);

  useEffect(() => {
    const updateUserAfterSignUp = async () => {
      let currentUser = null;

      try {
        currentUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
      } catch (error) {
        console.log("authentication", error);
      }

      if (currentUser) {
        try {
          const updateUserAttributes = await Auth.updateUserAttributes(
            currentUser,
            { "custom:guesty_id": inSignUpGuestyId }
          );

          setUser({ ...user, guesty_id: inSignUpGuestyId });

          setInSignUpGuestyId(null);
        } catch (error) {
          console.log("attributes", error);
        }
      }
    };

    if (inSignUpGuestyId && user) {
      updateUserAfterSignUp();
    }
  }, [inSignUpGuestyId, user]);

  const loginUser = async (email, password) => {
    try {
      const authResponse = await Auth.signIn(email, password);

      if (!authResponse.attributes["custom:guesty_id"]) {
        console.log("No Custom Id Found");

        let guestyId = null;

        try {
          const createGuestRes = await createGuest({
            firstName: authResponse?.attributes?.name.split(" ")[0],
            lastName: authResponse?.attributes?.name.split(" ")[1],
            email: authResponse?.attributes?.email,
          });

          guestyId = createGuestRes._id;

          const currentUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });

          try {
            const updateUserAttributes = await Auth.updateUserAttributes(
              currentUser,
              { "custom:guesty_id": guestyId }
            );
          } catch (error) {
            console.log("user attributes", error);
          }
        } catch (error) {
          console.log("creating guesty guest", error);
        }

        setUser({
          email: authResponse?.attributes?.email,
          name: authResponse?.attributes?.name,
          guesty_id: guestyId,
        });
      } else {
        setUser({
          email: authResponse?.attributes?.email,
          name: authResponse?.attributes?.name,
          guesty_id: authResponse?.attributes?.["custom:guesty_id"],
        });
      }

      setIsAuthenticated(true);
      return true;
    } catch (error) {
      setIsAuthenticated(false);
      console.log("logging in", error);
      return false;
    }
  };

  const currentUser = async () => {
    try {
      const authResponse = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      setUser({
        email: authResponse?.attributes?.email,
        name: authResponse?.attributes?.name,
        guesty_id: authResponse?.attributes?.["custom:guesty_id"],
      });
      setNeedsLoginChecked(false);
      setIsAuthenticated(true);

      return authResponse;
    } catch (error) {
      console.log("authenticating error", error);
      setIsAuthenticated(false);

      return false;
    }
  };

  const federatedSignInGoogle = async () => {
    const signIn = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      redirect: process.env.GATSBY_PUBLIC_GOOGLE_AUTH_REDIRECT,
    });
    setNeedsLoginChecked(true);
  };

  const federatedSignInApple = async () => {
    const signIn = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple,
      redirect: process.env.GATSBY_PUBLIC_GOOGLE_AUTH_REDIRECT,
    });
    setNeedsLoginChecked(true);
  };

  const logoutUser = async () => {
    try {
      await Auth.signOut();

      setIsAuthenticated(false);
      setNeedsLoginChecked(false);
      setUser({});
      navigate("/");
    } catch (error) {
      console.log("logging out", error);
    }
  };

  const checkForExistingAccount = async (payload) => {
    try {
      await Auth.signUp({
        username: payload.email,
        password: "ha",
        autoSignIn: {
          enabled: false,
        },
      });
    } catch (error) {
      if (error.toString().includes("UsernameExistsException")) {
        return true;
      }
      return false;
    }
  };

  const createAccount = async (payload) => {
    setNeedsLoginChecked(false);

    try {
      const authSignup = await Auth.signUp({
        username: payload.email,
        password: payload.password,
        attributes: {
          email: payload.email,
          name: payload.name,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      let guestyId = "";
      let marketingPolicy = payload?.marketingPolicy ? true : false;

      try {
        const createGuestRes = await createGuest({
          firstName: payload.name.split(" ")[0],
          lastName: payload.name.split(" ")[1],
          email: payload.email,
          phone: payload.phone,
          policy: { marketing: { isAccepted: marketingPolicy } },
        });

        guestyId = createGuestRes._id;
        setInSignUpGuestyId(createGuestRes._id);
      } catch (error) {
        console.log("guesty guest", error);
      }

      return guestyId;
    } catch (error) {
      console.log("signing up error", error);
      return false;
    }
  };

  const createAccountMain = async (payload) => {
    setNeedsLoginChecked(false);

    let authSignup;

    try {
      authSignup = await Auth.signUp({
        username: payload.email,
        password: payload.password,
        attributes: {
          email: payload.email,
          name: payload.name,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      let guestyId = "";
      let marketingPolicy = payload?.marketingPolicy ? true : false;

      try {
        const createGuestRes = await createGuest({
          firstName: payload.name.split(" ")[0],
          lastName: payload.name.split(" ")[1],
          email: payload.email,
          phone: payload.phone,
          policy: { marketing: { isAccepted: marketingPolicy } },
        });

        guestyId = createGuestRes._id;
        setInSignUpGuestyId(createGuestRes._id);
      } catch (error) {
        console.log("guesty guest", error);
      }

      return { status: true, data: guestyId };
    } catch (error) {
      console.log("signing up error", error);
      return { status: false, data: error };
    }
  };

  const createGuestyAccount = async () => {
    const userData = await currentUser();
    let guesty_id = "";
    let createGuestRes = null;

    try {
      createGuestRes = await createGuest({ email: userData.attributes.email });
      guesty_id = createGuestRes._id;
    } catch (error) {
      console.log("guesty account", error);
    }

    if (createGuestRes) {
      try {
        const updateUserAttributes = await Auth.updateUserAttributes(userData, {
          "custom:guesty_id": guesty_id,
        });
      } catch (error) {
        console.log("cognito user", error);
      }
    }
  };

  const confirmSignUp = async (email, verify) => {
    try {
      const confirmResponse = await Auth.confirmSignUp(email, verify);

      if (confirmResponse === "SUCCESS") {
        currentUser();
        setNeedsLoginChecked(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const resendCode = async (email) => {
    try {
      await Auth.resendSignUp(email);
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateUserGuestyId = async (id) => {
    let currentUser = null;

    try {
      currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
    } catch (error) {
      console.log("authenticating", error);
      return false;
    }

    if (currentUser) {
      try {
        const updateUserAttributes = await Auth.updateUserAttributes(
          currentUser,
          { "custom:guesty_id": id }
        );

        setUser({
          email: currentUser.attributes.email,
          name: currentUser.attributes.name,
          guesty_id: id,
        });

        setInSignUpGuestyId(null);

        return updateUserAttributes;
      } catch (error) {
        console.log("user attributes", error);
        return false;
      }
    }

    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        loginUser,
        logoutUser,
        createAccount,
        createAccountMain,
        federatedSignInGoogle,
        federatedSignInApple,
        confirmSignUp,
        createGuestyAccount,
        resendCode,
        currentUser,
        updateUserGuestyId,
        isAuthenticated,
        user,
        checkForExistingAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
