/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_cognito_identity_pool_id: process.env.GATSBY_AWS_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.GATSBY_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.GATSBY_AWS_OAUTH_DOMAIN,
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "phone"],
    redirectSignIn:
      "http://localhost:8000/account/,https://login.staysby4m.com/oauth2/idpresponse,https://staysby4m.com/account/,https://test-stays.moonello.com/account/",
    redirectSignOut:
      "http://localhost:8000/login/,https://staysby4m.com/login/,https://test-stays.moonello.com/login/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
