const axios = require("axios");
const { BackendApi } = require("./backendApi");

const api = new BackendApi(process.env.GATSBY_BACKEND_API_GUESTY_OPEN_BASE);

const getConversations = async (id) => {
  return axios
    .get(`${api.backendUrl}/conversations/posts/${id}`, api.config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log("### Error with Get Conversations Posts", {
        endpoint: `${api.backendUrl}/conversations/posts/${id}`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const postSendMessage = async (id, body) => {
  return axios
    .post(
      `${api.backendUrl}/conversations/${id}/posts`,
      { body },
      api.getConfig("POST")
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Create Post Send Message", {
        endpoint: `${api.backendUrl}/conversations/${id}/posts`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const getListings = async (query = null) => {
  return axios
    .get(`${api.backendUrl}/listings${query ? `?${query}` : ""}`, api.config)
    .then((response) => {
      return response.data?.results;
    })
    .catch((error) => {
      console.log("### Error with Get Listings", {
        endpoint: `${api.backendUrl}/listings${query ? `?${query}` : ""}`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const getListing = async (id, query = null) => {
  return axios
    .get(
      `${api.backendUrl}/listings/${id}${query ? `?${query}` : ""}`,
      api.config
    )
    .then((response) => {
      return response.data?.data;
    })
    .catch((error) => {
      console.log("### Error with Get Listings", {
        endpoint: `${api.backendUrl}/listings/${id}`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const getCustomFields = async () => {
  return axios
    .get(`${api.backendUrl}/accounts/custom-fields`, api.config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Get Custom Fields", {
        endpoint: `${api.backendUrl}/accounts/custom-fields`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const getTags = async () => {
  return axios
    .get(`${api.backendUrl}/listings/tags`, api.config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Get Tags", {
        endpoint: `${api.backendUrl}/listings/tags`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const getReservations = async (email = null) => {
  return axios
    .get(`${api.backendUrl}/reservations/${email ? email : ""}`, api.config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Get Reservations", {
        endpoint: `${api.backendUrl}/reservations`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const createReservation = async (data = null) => {
  return axios
    .post(`${api.backendUrl}/createReservation`, data, api.getConfig("POST"))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Create Reservation", {
        endpoint: `${api.backendUrl}/createReservation`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const createGuest = async (data = null) => {
  return axios
    .post(`${api.backendUrl}/guests/create`, data, api.getConfig("POST"))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Create Guest", {
        endpoint: `${api.backendUrl}/guests/create`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const getGuest = async (id) => {
  return axios
    .get(`${api.backendUrl}/guests/${id}`, api.getConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Get Guest", {
        endpoint: `${api.backendUrl}/guests/${id}`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const updateGuest = async (id, data = null) => {
  return axios
    .post(`${api.backendUrl}/guests/update/${id}`, data, api.getConfig("POST"))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Update Guest", {
        endpoint: `${api.backendUrl}/guests/update/${id}`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const postGetTasksByReservationIds = async (data) => {
  /* 
  data = {ids: []}
  */

  return axios
    .post(`${api.backendUrl}/tasks-open-api/tasks`, data, api.getConfig("POST"))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Post Get Tasks By Reservation Ids", {
        endpoint: `${api.backendUrl}/tasks-open-api/tasks`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const postCreateTask = async (data) => {
  /* 
  data = {title, description, reservationId, priority}

*/

  return axios
    .post(
      `${api.backendUrl}/tasks-open-api/create-single-task`,
      data,
      api.getConfig("POST")
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Post Create Task", {
        endpoint: `${api.backendUrl}/tasks-open-api/create-single-task`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

const putUpdateTask = async (id, data) => {
  /* 
  data = {title, description, reservationId, priority}
*/

  return axios
    .put(`${api.backendUrl}/tasks-open-api/${id}`, data, api.getConfig("PUT"))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("### Error with Put Update Task", {
        endpoint: `${api.backendUrl}/tasks-open-api/${id}`,
        api,
        error: JSON.stringify(error),
      });
      return [];
    });
};

module.exports = {
  getConversations,
  getGuest,
  getListings,
  getListing,
  getCustomFields,
  getTags,
  getReservations,
  createGuest,
  createReservation,
  postSendMessage,
  updateGuest,
  postCreateTask,
  postGetTasksByReservationIds,
  putUpdateTask,
};
