//

/* require("dotenv").config({
  backendApiToken: process.env.BACKEND_API_TOKEN,
  backendApiUrl: process.env.BACKEND_API_URL,
  port: process.env.PORT,
}); */

/* require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
}); */

const backendApiToken = process.env.GATSBY_BACKEND_API_TOKEN;
const backendApiUrl = process.env.GATSBY_BACKEND_API_URL;
const port = process.env.GATSBY_PORT;

// console.log(backendApiToken, backendApiUrl, port);

class BackendApi {
  constructor(service) {
    this.config = {
      headers: {
        Authorization: `Bearer ${backendApiToken}`,
        method: "GET",
        "Content-Type": "application/json",
      },
    };
    this.backendUrl = `${backendApiUrl}${port ? `:${port}` : ""}/${service}`;
  }
  getConfig(method = "GET") {
    return {
      ...this.config,
      headers: { ...this.config.headers, method },
    };
  }
}

module.exports = { BackendApi };
